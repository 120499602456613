import { cva, type VariantProps } from 'class-variance-authority';

import type { ReactNode } from 'react';

const descriptionItemStyles = cva(['flex flex-col gap-2 md:flex-row md:gap-10'], {
	variants: {
		align: {
			start: 'md:items-start',
			center: 'md:items-center',
		},
	},
	defaultVariants: {
		align: 'center',
	},
});

const descriptionItemLabelStyles = cva(
	['flex-shrink-0 text-pBody font-medium text-steel-darker defined-checkpoint-text-steel-darker'],
	{
		variants: {
			labelWidth: {
				md: 'md:w-40',
				sm: 'md:w-28',
				l: 'w-[500px]',
			},
			color: {
				signatures: 'defined-text-signatures-color',
			},
		},
		defaultVariants: {
			labelWidth: 'md',
		},
	},
);

type DescriptionItemStylesProps = VariantProps<typeof descriptionItemStyles>;
type DescriptionItemLabelStylesProps = VariantProps<typeof descriptionItemLabelStyles>;

export interface DescriptionItemProps
	extends DescriptionItemStylesProps,
		DescriptionItemLabelStylesProps {
	title: string | ReactNode;
	children: ReactNode;
}

export function DescriptionItem({
	title,
	align,
	labelWidth,
	children,
	color,
}: DescriptionItemProps) {
	return (
		<div className={descriptionItemStyles({ align })}>
			<dt className={descriptionItemLabelStyles({ labelWidth, color })}>{title}</dt>
			<dd className="ml-0 min-w-0 flex-1 leading-none">{children}</dd>
		</div>
	);
}

export type DescriptionListProps = {
	bg?: boolean;
	children: ReactNode;
};

export function DescriptionList({ children, bg }: DescriptionListProps) {
	return (
		<dl className={`flex flex-col ${bg ? 'defined-bg-4 gap-8 rounded-[8px] p-5' : 'mt-5 gap-5'} `}>
			{children}
		</dl>
	);
}
