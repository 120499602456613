import clsx from 'clsx';
import { useEffect, useState, useContext } from 'react';

import '../style.scss';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { ChevronDown12 } from '@mgonetwork/icons';
import { ReactComponent as DefaultLogo } from '../../assets/defaultLogo.svg';
import NetworkSelect from '../network/Network';
import ThemeSelect from '~/components/theme/ThemeSelect';
import { LinkWithQuery } from '~/ui/utils/LinkWithQuery';

import styles from '../HomeMetrics/homeMetrics.module.css';
import { Link } from '~/ui/Link';

import { accountContext } from '~/components/context/AccountContextProvider';
function Header() {
	const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);
	const { sendActiveTabEvent } = useContext(accountContext);
	useEffect(() => {
		const callback = () => {
			setIsScrolled(window.scrollY > 0);
		};
		document.addEventListener('scroll', callback, { passive: true });
		return () => {
			document.removeEventListener('scroll', callback);
		};
	}, []);

	const items: MenuProps['items'] = [
		{
			key: '1',
			label: (
				<Link to="/recent" variant="text3" onClick={() => sendActiveTabEvent('transactions')}>
					Transaction Blocks
				</Link>
			),
		},
		{
			key: '2',
			label: (
				<Link to="/recent?tab=epochs" variant="text3" onClick={() => sendActiveTabEvent('epochs')}>
					Epochs
				</Link>
			),
		},
		{
			key: '3',
			label: (
				<Link
					to="/recent?tab=checkpoints"
					variant="text3"
					onClick={() => sendActiveTabEvent('checkpoints')}
				>
					Checkpoints
				</Link>
			),
		},
		{
			key: '4',
			label: (
				<Link
					to="/recent?tab=popularPackages"
					variant="text3"
					onClick={() => sendActiveTabEvent('PopularPackages')}
				>
					Popular Packages
				</Link>
			),
		},
		{
			key: '5',
			label: (
				<Link to="/validators" variant="text3">
					Validators
				</Link>
			),
		},
	];

	return (
		<header
			className={clsx(
				'defined-bg-34 border-b-36 flex h-header justify-center overflow-visible backdrop-blur-xl transition-shadow',
				isScrolled && 'shadow-mistyEdge',
				/* styles['definedBlack/40'], */
			)}
		>
			<div className="flex h-full max-w-[1440px] flex-1 items-center gap-5 px-5 2xl:p-0">
				<div className="flex w-full gap-2 md:gap-10">
					{/*@ts-ignore*/}
					<LinkWithQuery className="default-logo-tag-a" to="/">
						<DefaultLogo className="default-logo-size" />
					</LinkWithQuery>
					<div className="flex flex-1 items-center text-[16px] font-semibold text-hero-dark">
						{/* <Search /> */}
						<div className="mr-5 flex items-center">
							<Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
								<Space>
									<span>Blockchain</span>
									<ChevronDown12 />
								</Space>
							</Dropdown>
						</div>
						<div className="mr-5">
							<Link to="/validators" variant="text3">
								Validators
							</Link>
						</div>
						{/* <div className='mr-5'>Statistics</div> */}
					</div>
					<NetworkSelect />
					<ThemeSelect /> {/* 切换主题颜色 */}
				</div>
			</div>
		</header>
	);
}

export default Header;
