import { Flag16, Info12, Nft16 } from '@mgonetwork/icons';
import { Heading, Placeholder, Text } from '@mgonetwork/ui';

import { ReactComponent as CallIcon } from './icons/transactions/call.svg';
import { Banner } from '~/ui/Banner';
import Search from '~/components/search/Search';
import { shortenString } from '~/utils/stringUtils';
import { CopyToClipboard } from '~/ui/CopyToClipboard';

export type PageHeaderType = 'Transaction' | 'Checkpoint' | 'Address' | 'Object' | 'Package';

export interface PageHeaderProps {
	title: string;
	subtitle?: string | null;
	type: PageHeaderType;
	status?: 'success' | 'failure';
	before?: React.ReactNode;
	error?: string;
	loading?: boolean;
}

const TYPE_TO_COPY: Partial<Record<PageHeaderType, string>> = {
	Transaction: 'Transaction Block',
};

const TYPE_TO_ICON: Record<PageHeaderType, typeof CallIcon | null> = {
	Transaction: null,
	Checkpoint: Flag16,
	Object: Nft16,
	Package: CallIcon,
	Address: null,
};

export function PageHeader({ title, subtitle, type, before, error, loading }: PageHeaderProps) {
	const Icon = TYPE_TO_ICON[type];
	return (
		<div data-testid="pageheader" className="group">
			<div className="epoch-component -z-[1] h-[400px]"></div>
			<div className="flex items-center justify-between gap-3 sm:gap-6">
				{/*bg-white/60*/}

				<div className="mt-12">
					<div>
						<div className="mb-1 flex items-center gap-2">
							{Icon && <Icon className="text-steel-dark" />}
							{loading ? (
								<Placeholder rounded="lg" width="140px" />
							) : (
								<Heading variant="heading6/semibold" color="steel-darker">
									<div className="flex items-center">
										<div className="text-[32px] text-hero-dark">
											{type in TYPE_TO_COPY ? TYPE_TO_COPY[type] : type}
										</div>
										<div>
											{before && (
												<div className="self-start sm:self-center">
													<div className="flex h-6 w-6  items-center justify-center rounded-lg">
														{loading ? (
															<Placeholder rounded="wh6" width="100%" height="100%" />
														) : (
															before
														)}
													</div>
												</div>
											)}
										</div>
									</div>
								</Heading>
							)}
						</div>
						<div className="mt-2 min-w-0 max-w-[230px] break-words break-all">
							{loading ? (
								<Placeholder rounded="lg" width="540px" height="20px" />
							) : (
								<div className="defined-bg-21 border-bc-1 flex items-center rounded-[8px] px-5 py-2.5">
									<Heading as="h3" variant="heading3/semibold" color="text-gr-dark" mono>
										{shortenString(title)}
									</Heading>
									<div className="ml-2 h-4 w-4 self-start md:h-6 md:w-6">
										<CopyToClipboard size="lg" color="steel" copyText={title} />
									</div>
								</div>
							)}
						</div>
						{subtitle && (
							<div className="mt-2 break-words">
								{
									loading ? <Placeholder rounded="lg" width="540px" height="20px" /> : null
									// <Text variant="body/medium" color="gray-75">
									// 	{subtitle}
									// </Text>
								}
							</div>
						)}
					</div>
					{error && (
						<div className="mt-2">
							<Banner variant="neutralWhite" icon={<Info12 className="text-issue-dark" />}>
								<Text variant="pBody/medium" color="issue-dark">
									{error}
								</Text>
							</Banner>
						</div>
					)}
				</div>
				<div className="w-[600px]">
					<Search />
				</div>
			</div>
		</div>
	);
}
