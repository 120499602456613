import clsx from 'clsx';
import { type ReactNode } from 'react';

export interface ListItemProps {
	active?: boolean;
	children: ReactNode;
	onClick?(): void;
}

export function ListItem({ active, children, onClick }: ListItemProps) {
	return (
		<li className="list-none">
			<button
				type="button"
				className={clsx(
					'defined-button-style block w-full cursor-pointer rounded-md border px-2.5 py-3 text-left text-body',
					active
						? 'defined-text-6 border-transparent font-semibold shadow-sm'
						: 'border-transparent font-medium text-hero-dark',
				)}
				onClick={onClick}
			>
				{children}
			</button>
		</li>
	);
}

export interface VerticalListProps {
	children: ReactNode;
}

export function VerticalList({ children }: VerticalListProps) {
	return <ul className="m-0 flex flex-col gap-1 p-0">{children}</ul>;
}
