import { useProductAnalyticsConfig } from '@mgonetwork/core';
import { Text } from '@mgonetwork/ui';

import { legalLinks } from './footerLinks';
import { Link } from '~/ui/Link';

export function LegalText() {
	return <>Copyright © 2022-{new Date().getFullYear()} Mango.All Rights Reserved</>;
}

export function LegalLinks() {
	const { data: productAnalyticsConfig } = useProductAnalyticsConfig();

	return (
		<ul className="flex flex-col gap-3 md:flex-row md:gap-8">
			{legalLinks.map(({ title, href }) => (
				<li className="flex items-center justify-center" key={href}>
					<Link variant="text" href={href}>
						<Text variant="subtitleSmall/medium" color="steel-darker">
							{title}
						</Text>
					</Link>
				</li>
			))}
			{productAnalyticsConfig?.mustProvideCookieConsent && (
				<li className="flex items-center justify-center">
					<Link variant="text" data-cc="c-settings">
						<Text variant="subtitleSmall/medium" color="steel-darker">
							Manage Cookies
						</Text>
					</Link>
				</li>
			)}
		</ul>
	);
}
