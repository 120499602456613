import React, { createContext, FC, ReactNode, useState } from 'react';

export interface accountContextType {
	tabActive: string;
	sendActiveTabEvent: (value: string) => void;
}

export const accountContext = createContext<accountContextType>({
	tabActive: '',
	sendActiveTabEvent: () => {},
});

const AccountContextProvider: FC<{ children?: ReactNode | undefined }> = (props) => {
	const [tabActive, setActiveTab] = useState('');
	const sendActiveTabEvent = (value: string) => {
		setActiveTab(value);
	};
	return (
		<accountContext.Provider
			value={{
				tabActive,
				sendActiveTabEvent,
			}}
		>
			{props.children}
		</accountContext.Provider>
	);
};

export default AccountContextProvider;
