import { useTimeAgo } from '@mgonetwork/core';

type Prop = {
	timestamp: number | undefined;
};

export function TxTimeType({ timestamp }: Prop) {
	const timeAgo = useTimeAgo({
		timeFrom: timestamp || null,
		shortedTimeLabel: true,
	});

	return (
		<section>
			<div className="w-20 text-caption text-gray-75">{timeAgo}</div>
		</section>
	);
}
