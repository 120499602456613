import { LoadingIndicator, RadioGroup, RadioGroupItem } from '@mgonetwork/ui';
import { useState } from 'react';
import { type Direction } from 'react-resizable-panels';

import { ErrorBoundary } from '../../../components/error-boundary/ErrorBoundary';
import PkgModulesWrapper from '../../../components/module/PkgModulesWrapper';
import { useGetTransaction } from '../../../hooks/useGetTransaction';
import { getOwnerStr } from '../../../utils/objectUtils';
import { trimStdLibPrefix } from '../../../utils/stringUtils';
import { type DataType } from '../ObjectResultType';
import TransactionBlocksForAddress, {
	FILTER_VALUES,
} from '~/components/TransactionBlocksForAddress/TransactionBlocksForAddress';
import { AddressLink, ObjectLink } from '~/ui/InternalLink';
import { TabHeader, Tabs, TabsContent, TabsList, TabsTrigger } from '~/ui/Tabs';

import styles from './ObjectView.module.css';

const GENESIS_TX_DIGEST = 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=';

const splitPanelsOrientation: { label: string; value: Direction }[] = [
	{ label: 'STACKED', value: 'vertical' },
	{ label: 'SIDE-BY-SIDE', value: 'horizontal' },
];

function PkgView({ data }: { data: DataType }) {
	const [selectedSplitPanelOrientation, setSplitPanelOrientation] = useState(
		splitPanelsOrientation[0].value,
	);

	const { data: txnData, isLoading } = useGetTransaction(data.data.tx_digest!);

	if (isLoading) {
		return <LoadingIndicator text="Loading data" />;
	}
	const viewedData = {
		...data,
		objType: trimStdLibPrefix(data.objType),
		tx_digest: data.data.tx_digest,
		owner: getOwnerStr(data.owner),
		publisherAddress:
			data.data.tx_digest === GENESIS_TX_DIGEST ? 'Genesis' : txnData?.transaction?.data.sender,
	};

	const checkIsPropertyType = (value: any) => ['number', 'string'].includes(typeof value);

	const properties = Object.entries(viewedData.data?.contents)
		.filter(([key, _]) => key !== 'name')
		.filter(([_, value]) => checkIsPropertyType(value));

	return (
		<div>
			<div>
				<div className="defined-tabs-card">
					<TabHeader title="Details" isList>
						<table className={styles.description} id="descriptionResults">
							<tbody className="mt-8 flex flex-col gap-8 px-5">
								<tr>
									<td>Object ID</td>
									<td id="objectID" className={styles.objectid}>
										<ObjectLink objectId={viewedData.id} noTruncate />
									</td>
								</tr>

								<tr>
									<td>Version</td>
									<td className="text-hero-dark">{viewedData.version}</td>
								</tr>

								{viewedData?.publisherAddress && (
									<tr>
										<td>Publisher</td>
										<td id="lasttxID">
											<AddressLink address={viewedData.publisherAddress} noTruncate />
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</TabHeader>
				</div>

				<Tabs defaultValue="modules">
					<TabsList>
						<div className="mt-16 flex w-full gap-10">
							<TabsTrigger value="modules">Modules</TabsTrigger>
							<TabsTrigger value="Transaction Blocks">Transaction Blocks </TabsTrigger>
						</div>
					</TabsList>
					<TabsContent value="modules" noGap>
						<div className="my-5 hidden md:block">
							<RadioGroup
								aria-label="split-panel-bytecode-viewer"
								value={selectedSplitPanelOrientation}
								onValueChange={(value: any) =>
									setSplitPanelOrientation(value as 'vertical' | 'horizontal')
								}
							>
								{splitPanelsOrientation.map(({ value, label }) => (
									<RadioGroupItem key={value} value={value} label={label} isStyle />
								))}
							</RadioGroup>
						</div>
						<div className="border-bc-1 rounded-[8px] p-5">
							<ErrorBoundary>
								<PkgModulesWrapper
									id={data.id}
									modules={properties}
									splitPanelOrientation={selectedSplitPanelOrientation}
								/>
							</ErrorBoundary>
						</div>
					</TabsContent>
					<TabsContent value="Transaction Blocks">
						<ErrorBoundary>
							<TransactionBlocksForAddress
								address={viewedData.id}
								filter={FILTER_VALUES.INPUT}
								isObject
							/>
						</ErrorBoundary>
					</TabsContent>
				</Tabs>

				{/* <div className={styles.txsection}>
					<ErrorBoundary>
						<TransactionBlocksForAddress
							address={viewedData.id}
							filter={FILTER_VALUES.INPUT}
							isObject
						/>
					</ErrorBoundary>
				</div> */}
			</div>
		</div>
	);
}

export default PkgView;
