import { useFormatCoin } from '@mgonetwork/core';
import { useLatestMgoSystemState } from '@mgonetwork/dapp-kit';
import { MGO_TYPE_ARG } from '@mgonetwork/mango.js/utils';
import { LoadingIndicator } from '@mgonetwork/ui';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { EpochProgress } from './stats/EpochProgress';
import { EpochStats } from './stats/EpochStats';
import { ValidatorStatus } from './stats/ValidatorStatus';
import { validatorsTableData } from '../validators/Validators';
import { PageLayout } from '~/components/Layout/PageLayout';
import { CheckpointsTable } from '~/components/checkpoints/CheckpointsTable';
import { useEnhancedRpcClient } from '~/hooks/useEnhancedRpc';
import { Banner } from '~/ui/Banner';
import { Stats, type StatsProps } from '~/ui/Stats';
import { TableCard } from '~/ui/TableCard';
import Search from '~/components/search/Search';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/ui/Tabs';
import { getEpochStorageFundFlow } from '~/utils/getStorageFundFlow';

function MgoStats({
	amount,
	...props
}: Omit<StatsProps, 'children'> & {
	amount: bigint | number | string | undefined | null;
}) {
	const [formattedAmount, symbol] = useFormatCoin(amount, MGO_TYPE_ARG);

	return (
		<Stats postfix={formattedAmount && symbol} {...props}>
			{formattedAmount || '--'}
		</Stats>
	);
}

export default function EpochDetail() {
	const { id } = useParams();
	const enhancedRpc = useEnhancedRpcClient();
	const { data: systemState } = useLatestMgoSystemState();
	const { data, isLoading, isError } = useQuery({
		queryKey: ['epoch', id],
		queryFn: async () =>
			enhancedRpc.getEpochs({
				// todo: endpoint returns no data for epoch 0
				cursor: id === '0' ? undefined : (Number(id!) - 1).toString(),
				limit: 1,
			}),
	});

	const [epochData] = data?.data ?? [];
	const isCurrentEpoch = useMemo(
		() => systemState?.epoch === epochData?.epoch,
		[systemState, epochData],
	);

	const validatorsTable = useMemo(() => {
		if (!epochData?.validators) return null;
		// todo: enrich this historical validator data when we have
		// at-risk / pending validators for historical epochs
		return validatorsTableData(
			[...epochData.validators].sort(() => 0.5 - Math.random()),
			[],
			[],
			null,
		);
	}, [epochData]);

	if (isLoading) return <PageLayout content={<LoadingIndicator />} />;

	if (isError || !epochData)
		return (
			<PageLayout
				content={
					<Banner variant="error" fullWidth>
						{`There was an issue retrieving data for epoch ${id}.`}
					</Banner>
				}
			/>
		);

	const { fundInflow, fundOutflow, netInflow } = getEpochStorageFundFlow(epochData.endOfEpochInfo);

	// cursor should be the sequence number of the last checkpoint + 1  if we want to query with desc. order
	const initialCursorPlusOne = epochData.endOfEpochInfo?.lastCheckpointId
		? (Number(epochData.endOfEpochInfo?.lastCheckpointId) + 1).toString()
		: undefined;
	console.log('isCurrentEpoch', isCurrentEpoch);
	return (
		<PageLayout
			content={
				<div className="flex flex-col">
					<div className="text-hero-dark">
						<div className="flex justify-between">
							<div className="home-search-title text-hero-dark">Epoch</div>
							<div className="mt-12 w-[600px]">
								<Search />
							</div>
						</div>
					</div>
					<div className="epoch-component -t-[68px] z-[-1] h-[500px]"></div>
					<div
						className={`mb-14  grid gap-5 ${isCurrentEpoch ? 'grid-cols-66-33' : 'grid-cols-1'}`}
					>
						{' '}
						{/* grid grid-flow-row gap-4 sm:gap-2 md:flex md:gap-6 */}
						{isCurrentEpoch ? (
							<div>
								<div className="flex min-w-[136px] max-w-full">
									<EpochProgress
										epoch={epochData.epoch}
										inProgress={isCurrentEpoch}
										start={Number(epochData.epochStartTimestamp)}
										end={Number(epochData.endOfEpochInfo?.epochEndTimestamp ?? 0)}
									/>
								</div>
								<div className="grid grid-cols-2 gap-5 pt-5">
									<EpochStats label="Rewards">
										<MgoStats
											label="Total Stake"
											tooltip=""
											orientation="horizontal"
											amount={epochData.endOfEpochInfo?.totalStake}
										/>
										<MgoStats
											label="Stake Subsidies"
											orientation="horizontal"
											amount={epochData.endOfEpochInfo?.stakeSubsidyAmount}
										/>
										<MgoStats
											label="Stake Rewards"
											orientation="horizontal"
											amount={epochData.endOfEpochInfo?.totalStakeRewardsDistributed}
										/>
										<MgoStats
											label="Gas Fees"
											orientation="horizontal"
											amount={epochData.endOfEpochInfo?.totalGasFees}
										/>
									</EpochStats>
									<EpochStats label="Storage Fund Balance">
										<MgoStats
											label="Fund Size"
											orientation="horizontal"
											amount={epochData.endOfEpochInfo?.storageFundBalance}
										/>
										<MgoStats label="Net Inflow" orientation="horizontal" amount={netInflow} />
										<MgoStats label="Fund Inflow" orientation="horizontal" amount={fundInflow} />
										<MgoStats label="Fund Outflow" orientation="horizontal" amount={fundOutflow} />
									</EpochStats>
								</div>
							</div>
						) : (
							<div className="grid grid-cols-3 gap-5">
								<EpochProgress
									epoch={epochData.epoch}
									inProgress={isCurrentEpoch}
									start={Number(epochData.epochStartTimestamp)}
									end={Number(epochData.endOfEpochInfo?.epochEndTimestamp ?? 0)}
								/>
								<EpochStats label="Rewards">
									<MgoStats
										label="Total Stake"
										tooltip=""
										orientation="horizontal"
										amount={epochData.endOfEpochInfo?.totalStake}
									/>
									<MgoStats
										label="Stake Subsidies"
										orientation="horizontal"
										amount={epochData.endOfEpochInfo?.stakeSubsidyAmount}
									/>
									<MgoStats
										label="Stake Rewards"
										orientation="horizontal"
										amount={epochData.endOfEpochInfo?.totalStakeRewardsDistributed}
									/>
									<MgoStats
										label="Gas Fees"
										orientation="horizontal"
										amount={epochData.endOfEpochInfo?.totalGasFees}
									/>
								</EpochStats>
								<EpochStats label="Storage Fund Balance">
									<MgoStats
										label="Fund Size"
										orientation="horizontal"
										amount={epochData.endOfEpochInfo?.storageFundBalance}
									/>
									<MgoStats label="Net Inflow" orientation="horizontal" amount={netInflow} />
									<MgoStats label="Fund Inflow" orientation="horizontal" amount={fundInflow} />
									<MgoStats label="Fund Outflow" orientation="horizontal" amount={fundOutflow} />
								</EpochStats>
							</div>
						)}
						{isCurrentEpoch ? <ValidatorStatus /> : null}
					</div>

					<Tabs size="lg" defaultValue="checkpoints">
						<TabsList>
							<TabsTrigger value="checkpoints">Checkpoints</TabsTrigger>
							<TabsTrigger value="validators">Participating Validators</TabsTrigger>
						</TabsList>
						<TabsContent value="checkpoints">
							<CheckpointsTable
								initialCursor={initialCursorPlusOne}
								maxCursor={epochData.firstCheckpointId}
								initialLimit={20}
							/>
						</TabsContent>
						<TabsContent value="validators">
							{validatorsTable ? (
								<TableCard
									data={validatorsTable.data}
									columns={validatorsTable.columns}
									sortTable
								/>
							) : null}
						</TabsContent>
					</Tabs>
				</div>
			}
		/>
	);
}
