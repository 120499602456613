import { getRefGasPrice } from '@mgonetwork/core';
import { useLatestMgoSystemState } from '@mgonetwork/dapp-kit';
import { Heading, Text } from '@mgonetwork/ui';
import { useMemo } from 'react';

import { Card } from '~/ui/Card';
import { RingChart, RingChartLegend } from '~/ui/RingChart';

export function ValidatorStatus() {
	const { data } = useLatestMgoSystemState();

	const nextRefGasPrice = useMemo(
		() => getRefGasPrice(data?.activeValidators),
		[data?.activeValidators],
	);

	if (!data) return null;

	const nextEpoch = Number(data.epoch || 0) + 1;

	const chartData = [
		{
			value: data.activeValidators.length,
			label: 'Active',
			gradient: {
				deg: 315,
				values: [
					{ percent: 0, color: '#46ADB3' },
					{ percent: 100, color: 'rgb(70 173 179)' },
				],
			},
		},
		{
			value: Number(data.pendingActiveValidatorsSize ?? 0),
			label: 'New',
			color: '#F2BD24',
		},
		{
			value: data.atRiskValidators.length,
			label: 'At Risk',
			color: '#FF794B',
		},
	];

	return (
		<Card spacing="lg" bg="definedBlack" rounded="2xl" grid={true}>
			<div className="defined-bg-20 mb-2 rounded-[8px] p-5">
				<Heading color="steel-darker" variant="heading3/semibold">
					{`Validators in Epoch ${nextEpoch}`}
				</Heading>
			</div>
			<div className="flex items-center gap-5">
				<div className="m-0-auto min-h-[300px] min-w-[300px]">
					<RingChart data={chartData} />
				</div>
				{/* <div className="self-start">
					<RingChartLegend data={chartData} title="" />
				</div> */}
			</div>

			<div className="flex items-center justify-between rounded-lg  px-3 py-2">
				<div className="grid-cols-rows grid">
					<Text variant="pSubtitle/semibold" color="steel-darker">
						Estimated Next Epoch
					</Text>
					<div className="h-7.5"></div>
					<Text variant="pSubtitle/semibold" color="steel-darker">
						Reference Gas Price
					</Text>
				</div>
				<div className="text-right">
					<Heading variant="heading4/normal" color="text-gr-dark">
						{nextRefGasPrice.toString()}
					</Heading>
					<div className="h-7.5"></div>
					<Text variant="pBody/normal" color="text-gr-dark">
						MANGO
					</Text>
				</div>
			</div>
		</Card>
	);
}
