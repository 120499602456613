import { CoinFormat, useFormatCoin } from '@mgonetwork/core';
import { MGO_TYPE_ARG } from '@mgonetwork/mango.js/utils';
import { Text } from '@mgonetwork/ui';

export function MgoAmount({
	amount,
	full = false,
}: {
	amount?: bigint | number | string | null;
	full?: boolean;
}) {
	const [formattedAmount, coinType] = useFormatCoin(
		amount,
		MGO_TYPE_ARG,
		full ? CoinFormat.FULL : CoinFormat.ROUNDED,
	);
	if (!amount) return <Text variant="bodySmall/medium">--</Text>;

	return (
		<div className="leading-1 flex items-end gap-0.5">
			<Text variant="bodySmall/medium" color="text-hero-dark">
				{formattedAmount}
			</Text>
			<Text variant="captionSmall/normal" color="text-hero-dark">
				{coinType}
			</Text>
		</div>
	);
}
