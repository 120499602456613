import {
	type BalanceChangeSummary,
	CoinFormat,
	useFormatCoin,
	useCoinMetadata,
	type BalanceChange,
	useResolveMgoNSName,
	getRecognizedUnRecognizedTokenChanges,
} from '@mgonetwork/core';
import { Heading, Text } from '@mgonetwork/ui';
import { useMemo } from 'react';

import { Banner } from '~/ui/Banner';
import { Coin } from '~/ui/CoinsStack';
import { AddressLink } from '~/ui/InternalLink';
import { TransactionBlockCard, TransactionBlockCardSection } from '~/ui/TransactionBlockCard';

interface BalanceChangesProps {
	changes: BalanceChangeSummary;
}

function BalanceChangeEntry({ change }: { change: BalanceChange }) {
	const { amount, coinType, recipient, unRecognizedToken } = change;
	const [formatted, symbol] = useFormatCoin(amount, coinType, CoinFormat.FULL);
	const { data: coinMetaData } = useCoinMetadata(coinType);
	const isPositive = BigInt(amount) > 0n;

	if (!change) {
		return null;
	}

	return (
		<div className="flex flex-col gap-2 py-3 first:pt-0 only:pb-0 only:pt-0">
			<div className="flex justify-between gap-1 px-4">
				<div className="flex gap-2">
					<div className="w-5">
						<Coin type={coinType} />
					</div>
					<div className="flex flex-wrap gap-2 gap-y-1">
						<Text variant="pBody/semibold" color="text-success">
							{coinMetaData?.name || symbol}
						</Text>
						{unRecognizedToken && (
							<Banner variant="warning" icon={null} border spacing="sm">
								<div className="max-w-[70px] overflow-hidden truncate whitespace-nowrap text-captionSmallExtra font-medium uppercase leading-3 tracking-wider lg:max-w-full">
									Unrecognized
								</div>
							</Banner>
						)}
					</div>
				</div>

				<div className="flex justify-end text-right">
					<Text variant="pBody/medium" color={isPositive ? 'success-dark' : 'issue-dark'}>
						{isPositive ? '+' : ''}
						{formatted} {symbol}
					</Text>
				</div>
			</div>

			{recipient && (
				<div className="flex flex-wrap items-center justify-between border-t border-gray-45 pt-2">
					<Text variant="pBody/medium" color="steel-dark">
						Recipient
					</Text>
					<AddressLink address={recipient} />
				</div>
			)}
		</div>
	);
}

function BalanceChangeCard({ changes, owner }: { changes: BalanceChange[]; owner: string }) {
	const { data: mgonsDomainName } = useResolveMgoNSName(owner);
	const { recognizedTokenChanges, unRecognizedTokenChanges } = useMemo(
		() => getRecognizedUnRecognizedTokenChanges(changes),
		[changes],
	);
	return (
		<TransactionBlockCard
			title={
				<div className="defined-bg-20 flex w-full flex-wrap items-center justify-between gap-y-2 rounded-[4px] p-5">
					<Heading variant="heading6/semibold" color="steel-darker">
						Balance Changes
					</Heading>
				</div>
			}
			shadow
			size="sm"
			footer={
				owner ? (
					<div className="flex flex-wrap justify-between px-8">
						<Text variant="pBody/medium" color="steel-dark">
							Owner
						</Text>
						<Text variant="pBody/medium" color="white">
							<AddressLink label={mgonsDomainName || undefined} address={owner} />
						</Text>
					</div>
				) : null
			}
		>
			<div className="flex flex-col gap-2">
				{recognizedTokenChanges.map((change, index) => (
					<TransactionBlockCardSection key={index + change.coinType}>
						<BalanceChangeEntry change={change} />
					</TransactionBlockCardSection>
				))}
				{unRecognizedTokenChanges.length > 0 && (
					<div className="flex flex-col gap-2 border-t border-gray-45 pt-2">
						{unRecognizedTokenChanges.map((change, index) => (
							<TransactionBlockCardSection key={index + change.coinType}>
								<BalanceChangeEntry change={change} />
							</TransactionBlockCardSection>
						))}
					</div>
				)}
			</div>
		</TransactionBlockCard>
	);
}

export function BalanceChanges({ changes }: BalanceChangesProps) {
	if (!changes) return null;

	return (
		<>
			{Object.entries(changes).map(([owner, changes]) => (
				<BalanceChangeCard key={owner} changes={changes} owner={owner} />
			))}
		</>
	);
}
