import { type MgoTransactionBlockResponse } from '@mgonetwork/mango.js/client';
import {
	parseSerializedSignature,
	type SignatureScheme,
	type PublicKey,
} from '@mgonetwork/mango.js/cryptography';
import { parsePartialSignatures } from '@mgonetwork/mango.js/multisig';
import { toB64, normalizeMgoAddress } from '@mgonetwork/mango.js/utils';
import { publicKeyFromRawBytes } from '@mgonetwork/mango.js/verify';
import { Text } from '@mgonetwork/ui';

import { DescriptionItem, DescriptionList } from '~/ui/DescriptionList';
import { AddressLink } from '~/ui/InternalLink';
import { TabHeader } from '~/ui/Tabs';

interface SignaturePubkeyPair {
	signatureScheme: SignatureScheme;
	publicKey: PublicKey;
	signature: Uint8Array;
}

function SignaturePanel({ title, signature }: { title: string; signature: SignaturePubkeyPair }) {
	return (
		<TabHeader title={title} isList>
			<div className="p-5 pt-2">
				<DescriptionList>
					<DescriptionItem title="Scheme" align="start" color="signatures" labelWidth="sm">
						<Text variant="pBody/medium" color="text-hero-dark">
							{signature.signatureScheme}
						</Text>
					</DescriptionItem>
					<DescriptionItem title="Address" align="start" color="signatures" labelWidth="sm">
						<AddressLink
							className="defined-text-42 break-all font-mono text-body  font-medium hover:text-hero-darkest"
							noTruncate
							address={signature.publicKey.toMgoAddress()}
						/>
					</DescriptionItem>
					<DescriptionItem title="Mgo Public Key" align="start" color="signatures" labelWidth="sm">
						<Text variant="pBody/medium" color="text-hero-dark">
							{signature.publicKey.toMgoPublicKey()}
						</Text>
					</DescriptionItem>
					<DescriptionItem title="Signature" align="start" color="signatures" labelWidth="sm">
						<Text variant="pBody/medium" color="text-hero-dark">
							{toB64(signature.signature)}
						</Text>
					</DescriptionItem>
				</DescriptionList>
			</div>
		</TabHeader>
	);
}

function getSignatureFromAddress(signatures: SignaturePubkeyPair[], mgoAddress: string) {
	return signatures.find(
		(signature) => signature.publicKey.toMgoAddress() === normalizeMgoAddress(mgoAddress),
	);
}

function getSignaturesExcludingAddress(
	signatures: SignaturePubkeyPair[],
	mgoAddress: string,
): SignaturePubkeyPair[] {
	return signatures.filter(
		(signature) => signature.publicKey.toMgoAddress() !== normalizeMgoAddress(mgoAddress),
	);
}
interface Props {
	transaction: MgoTransactionBlockResponse;
}

export function Signatures({ transaction }: Props) {
	const sender = transaction.transaction?.data.sender;
	const gasData = transaction.transaction?.data.gasData;
	const transactionSignatures = transaction.transaction?.txSignatures;

	if (!transactionSignatures) return null;

	const isSponsoredTransaction = gasData?.owner !== sender;

	const deserializedTransactionSignatures = transactionSignatures
		.map((signature) => {
			const parsed = parseSerializedSignature(signature);

			if (parsed.signatureScheme === 'MultiSig') {
				return parsePartialSignatures(parsed.multisig);
			}

			return {
				...parsed,
				publicKey: publicKeyFromRawBytes(parsed.signatureScheme, parsed.publicKey),
			};
		})
		.flat();

	const userSignatures = isSponsoredTransaction
		? getSignaturesExcludingAddress(deserializedTransactionSignatures, gasData!.owner)
		: deserializedTransactionSignatures;

	const sponsorSignature = isSponsoredTransaction
		? getSignatureFromAddress(deserializedTransactionSignatures, gasData!.owner)
		: null;

	return (
		<div className="flex flex-col gap-8">
			{userSignatures.length > 0 && (
				<div className="flex flex-col gap-8 rounded-2xl border border-gray-45 p-5">
					{userSignatures.map((signature, index) => (
						<div key={index}>
							<SignaturePanel title="User Signature" signature={signature} />
						</div>
					))}
				</div>
			)}
			{sponsorSignature && (
				<SignaturePanel title="Sponsor Signature" signature={sponsorSignature} />
			)}
		</div>
	);
}
