import { ReactComponent as DefaultLogo } from '../../assets/defaultLogo.svg';
import { ReactComponent as Discord } from '../../assets/discord.svg';
import { ReactComponent as M } from '../../assets/M.svg';
import { ReactComponent as X } from '../../assets/x.svg';
import { LinkWithQuery } from '~/ui/utils/LinkWithQuery';
import { Link } from '~/ui/Link';
import { LegalText } from '~/components/footer/Legal';

function Footer() {
	const BLOG_LINK = import.meta.env.VITE_BLOG_LINK as string;
	const WHITEPAPER_LINK = import.meta.env.VITE_WHITEPAPER_LINK as string;
	const DOCS_LINK = import.meta.env.VITE_DOCS_LINK as string;
	const GITHUB_LINK = import.meta.env.VITE_GITHUB_LINK as string;
	const DISCORD_LINK = import.meta.env.VITE_DISCORD_LINK as string;
	const MEDIUN_LINK = import.meta.env.VITE_MEDIUN_LINK as string;
	const X_LINK = import.meta.env.VITE_X_LINK as string;
	const TERMS_AND_CONDITIONS_LINK = import.meta.env.VITE_TERMS_AND_CONDITIONS_LINK as string;
	const PRIVACY_POLICY_LINK = import.meta.env.VITE_PRIVACY_POLICY_LINK as string;

	return (
		<footer className="defined-footer-bg sticky top-[100%] px-5 py-10 md:px-10 md:py-14">
			<div className=" h-full max-w-[1440px] gap-5 px-5 2xl:p-0" style={{ margin: '0 auto' }}>
				<div className="defined-footer-top flex">
					<LinkWithQuery className="default-logo-tag-a marginBottom30" to="/">
						<DefaultLogo className="default-logo-size" />
					</LinkWithQuery>
					<div className="defined-footer-lists  flex">
						<Link className="default-link-tag-a marginBottom30" href={BLOG_LINK}>
							Blog
						</Link>
						<Link className="default-link-tag-a marginBottom30" href={WHITEPAPER_LINK}>
							Whitepaper
						</Link>
						<Link className="default-link-tag-a marginBottom30" href={DOCS_LINK}>
							Docs
						</Link>
						<Link className="default-link-tag-a marginBottom30" href={GITHUB_LINK}>
							Github
						</Link>
						<Link className="default-link-tag-a marginBottom30" href={DISCORD_LINK}>
							<Discord />
						</Link>
						<Link className="default-link-tag-a marginBottom30" href={MEDIUN_LINK}>
							<M />
						</Link>
						<Link className="default-link-tag-a marginBottom30" href={X_LINK}>
							<X />
						</Link>
					</div>
				</div>
				<div className="defined-footer-bottom flex">
					<p className="marginTop30">
						<LegalText />
					</p>
					<span className="flex">
						<Link className="marginTop30 default-footer-tag-a " href={TERMS_AND_CONDITIONS_LINK}>
							Terms & Conditions
						</Link>
						<Link className="default-footer-tag-a marginTop30" href={PRIVACY_POLICY_LINK}>
							Privacy Policy
						</Link>
					</span>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
